export const billingHeaders = [
	{
		text: 'Order #',
		value: 'order_number',
		sortable: true,
		width: '18%'
	},
	{
		text: 'Issued',
		value: '_issueDate',
		sortable: true,
		width: '18%'
	},
	{
		text: 'Amount',
		value: 'order_item_subtotal',
		sortable: true,
		width: '18%'
	},
	{
		text: 'Balance Due',
		value: 'order_total_due',
		sortable: true,
		width: '18%'
	},
	{
		text: 'Status',
		value: '_order_status_string',
		sortable: true,
		width: '18%'
	},
	{
		text: '',
		value: 'actions',
		align: 'end',
	},
]
