<template>
  <th
    v-if="header.sortable || defaults.sortable"
    :class="`align-${header.align || defaults.align} text-${
      header.align || defaults.align
    }`"
    :style="{
      width: header.width || defaults.width,
    }"
  >
    <span class="sortable-header" @click="$emit('onSortChange', header.value)">
      {{ header.text }}
      <Icon
        :class="{ invisible: !isSorting }"
        :name="sortingIcon"
        family="fas"
      />
    </span>
  </th>
  <th
    v-else
    :class="`align-${header.align || defaults.align} text-${
      header.align || defaults.align
    }`"
    :style="{
      width: header.width || defaults.width,
    }"
  >
    {{ header.text }}
  </th>
</template>

<script>
import Icon from 'common/components/Icon';

import { tableSettings } from '../../constants/table';

export default {
  components: {
    Icon,
  },
  props: {
    header: {
      type: Object,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    currentSort: {
      type: String,
      default: '',
    },
    currentOrder: {
      type: Number,
      default: 1,
    },
    align: {
      type: String,
      default: 'center',
    },
    width: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tableSettings,
      defaults: tableSettings.defaults,
    };
  },
  computed: {
    center() {
      return false;
    },
    isSorting() {
      return this.currentSort === this.value;
    },
    sortingIcon() {
      return this.currentOrder === 1 ? 'caret-down' : 'caret-up';
    },
  },
};
</script>
