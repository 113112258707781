<template>
  <div>
    <table
      v-if="data.length"
      class="table fc-table"
      :class="{ 'show-hover': hover }"
    >
      <thead>
        <tr>
          <TableHeader
            v-for="header in headers"
            :key="header.value"
            :header="header"
            :text="header.text"
            :value="header.value"
            :sortable="header.sortable"
            :align="header.align"
            :current-sort="currentSort"
            :current-order="currentOrder"
            @onSortChange="onSortChange"
          />
        </tr>
      </thead>
      <tbody>
        <TableRow
          v-for="(row, index) of sortedItems"
          :key="index"
          :row-data="row"
          :headers="headers"
        >
          <template
            v-for="(_, slot) in $scopedSlots"
            #[slot]="{ item, rowdata }"
          >
            <slot :name="slot" :item="item" :rowdata="rowdata" />
          </template>
        </TableRow>
      </tbody>
    </table>
    <div v-else-if="query" class="empty-state">
      {{$t('There is no results, try cleaning filters.')}}
    </div>
    <div v-else class="empty-state">{{ $t('No Records Found') }}</div>
  </div>
</template>

<script>
import TableRow from './TableRow.vue';
import TableHeader from './TableHeader.vue';
import _ from 'lodash';

export default {
  components: {
    TableRow,
    TableHeader,
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    data: {
      required: true,
      type: Array,
    },
    headers: {
      required: true,
      type: Array,
    },
    hover: {
      type: Boolean,
      required: false,
      default: false,
    },
    sortBy: {
      type: String,
      required: false,
      default: '',
    },
    query: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      currentSort: 'order_number',
      currentOrder: 1,
    };
  },
  computed: {
    sortedItems() {
      if (this.currentSort)
        return _.orderBy(
          this.data,
          [this.currentSort],
          [this.currentOrder == 1 ? 'asc' : 'desc']
        );
      return this.data;
    },
  },
  watch: {
    sortBy: {
      immediate: true,
      handler(sortBy) {
        this.currentSort = sortBy;
      },
    },
  },
  methods: {
    onSortChange(value) {
      this.currentSort = value;
      this.currentOrder = this.currentOrder === 1 ? -1 : 1;
      this.$emit('onSortChange', {
        sortBy: value,
        order: this.currentOrder,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-state {
  color: var(--text-light);
  font-size: 12px;
  margin-top: 30px;
  text-align: center;
}
</style>
