<template>
  <td
    :class="`align-${column.align || defaults.align} text-${
      column.align || defaults.align
    }`"
  >
    <slot :name="`item.${column.value}`" :item="data" :rowdata="row">{{ data }}</slot>
  </td>
</template>

<script>
import { tableSettings } from '../../constants/table';

export default {
  props: {
    column: {
      type: Object,
      required: true,
    },
    data: {
      type: [String, Number, Object],
      required: false,
      default: '',
    },
    row: {
      type: Object,
      required: false,
      default: () => {return {}},
    }
  },
  data() {
    return {
      tableSettings,
      defaults: tableSettings.defaults,
    };
  },
};
</script>

<style>
</style>