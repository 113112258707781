<template>
  <tr @click="(event) => $parent.$emit('click:row', { event, data: rowData })">
    <TableRowData
      v-for="(column, index) in headers"
      :key="index"
      :column="column"
      :data="rowData[column.value]"
      :row="rowData"
    >
      <template #[getSlotName(column)]="{ item, rowdata }">
        <slot
          :name="getSlotName(column)"
          :item="item"
          :rowdata="rowdata"
        ></slot>
      </template>
    </TableRowData>
  </tr>
</template>

<script>
import TableRowData from './TableRowData.vue';

import { tableSettings } from '../../constants/table';

export default {
  components: {
    TableRowData,
  },
  props: {
    rowData: {
      type: Object,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      tableSettings,
      defaults: tableSettings.defaults,
    };
  },
  methods: {
    getSlotName(column) {
      return `item.${column.value}`;
    },
  },
};
</script>