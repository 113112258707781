var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"billing-wrapper"},[_c('section',{staticClass:"section-header"},[_c('div',{staticClass:"container-lg"},[_c('h2',[_c('Icon',{staticClass:"mr-3",attrs:{"family":"fal","name":"receipt"}}),_vm._v(" "+_vm._s(_vm.$t('Orders History'))+" ")],1)])]),_c('main',{staticClass:"main-wrapper",attrs:{"role":"main"}},[_c('Loader',{attrs:{"full":"","loading":_vm.loading}}),_c('div',{staticClass:"filter-row mb-3"},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('Filter')))]),_c('InputBox',{attrs:{"prepends":[{ icon: 'search' }],"placeholder":_vm.$t('Filter orders...'),"appends":[
            _vm.query ? { icon: 'times', action: function () { return (_vm.query = ''); } } : {} ],"form-size":"sm","rounded":""},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1),_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.$modal.show('checkout')}}},[_vm._v(" "+_vm._s(_vm.$t('Buy new license'))+" ")])]),_c('Table',{staticClass:"billing-table",attrs:{"headers":_vm.billingHeaders,"data":_vm.orders,"sort-by":"order_number","hover":"","query":_vm.query},on:{"click:row":function (event) { return _vm.orderSummary(event.data.order_number); }},scopedSlots:_vm._u([{key:"item.order_item_subtotal",fn:function(ref){
          var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatInlinePrice(item)))])]}},{key:"item.order_total_due",fn:function(ref){
          var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatInlinePrice(item)))])]}},{key:"item._order_status_string",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"billing-status",class:item},[_vm._v(" "+_vm._s(item)+" ")])]}},{key:"item.actions",fn:function(ref){
          var rowdata = ref.rowdata;
return [_c('DropDownList',{staticClass:"billing-actions",attrs:{"items":_vm.orderOptions,"target":{order:rowdata}},on:{"triggerDDAction":function (e) { return _vm.triggerAction(e, rowdata); }},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_c('Icon',{attrs:{"family":"fal","name":"ellipsis-h"}})],1)]}}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }