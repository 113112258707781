<template>
  <div class="billing-wrapper">
    <section class="section-header">
      <div class="container-lg">
        <h2>
          <Icon class="mr-3" family="fal" name="receipt" />
          {{ $t('Orders History') }}
        </h2>
      </div>
    </section>

    <main role="main" class="main-wrapper">
      <Loader full :loading="loading" />

      <div class="filter-row mb-3">
        <div>
          <span>{{ $t('Filter') }}</span>

          <InputBox
            v-model="query"
            :prepends="[{ icon: 'search' }]"
            :placeholder="$t('Filter orders...')"
            :appends="[
              query ? { icon: 'times', action: () => (query = '') } : {},
            ]"
            form-size="sm"
            rounded
          />
        </div>

        <button class="btn btn-primary" @click="$modal.show('checkout')">
          {{ $t('Buy new license') }}
        </button>
      </div>

      <Table
        :headers="billingHeaders"
        :data="orders"
        class="billing-table"
        sort-by="order_number"
        hover
        :query="query"
        @click:row="(event) => orderSummary(event.data.order_number)"
      >
        <template #[`item.order_item_subtotal`]="{ item }">
          <div>{{ formatInlinePrice(item) }}</div>
        </template>
        <template #[`item.order_total_due`]="{ item }">
          <div>{{ formatInlinePrice(item) }}</div>
        </template>
        <template #[`item._order_status_string`]="{ item }">
          <div class="billing-status" :class="item">
            {{ item }}
          </div>
        </template>
        <template #[`item.actions`]="{ rowdata }">
          <DropDownList
            :items="orderOptions"
            :target={order:rowdata}
            class="billing-actions"
            @triggerDDAction="(e) => triggerAction(e, rowdata)"
            @click.native.stop
          >
            <Icon family="fal" name="ellipsis-h" />
          </DropDownList>
        </template>
      </Table>
    </main>
  </div>
</template>

<script>
import InputBox from '../components/InputBox.vue';
import Loader from 'common/components/Loader.vue';
import Icon from 'common/components/Icon.vue';
import Table from '../components/Table/Table';
import DropDownList from 'common/components/DropDownList';
import { formatInlinePrice } from '@/utils/checkout';

import { billingHeaders } from '../constants/billing';

import Fuse from 'fuse.js';

import dayjs from 'dayjs';
var isToday = require('dayjs/plugin/isToday');
dayjs.extend(isToday);

export default {
  name: 'Billing',
  components: {
    DropDownList,
    InputBox,
    Loader,
    Icon,
    Table,
  },
  data() {
    return {
      query: '',
      billingHeaders,
    };
  },
  computed: {
    isMobile() {
      return true;
    },
    loading() {
      return this.$store.state.loading['core/getBillingInfo'];
    },
    user() {
      return this.$store.state.core.user;
    },
    orders() {
      let _orders = this.$store.state.core.orders;
      
      _orders = _orders.map((order) => {
        return {
          ...order,
          _issueDate: dayjs(order.issue_date).format('DD MMM YYYY'),
          _order_status_string: this.getOrderStatus(order),
          _order_status_string_translated: this.$t(this.getOrderStatus(order)),
        };
      });

      if (this.query) {
        const fuse = new Fuse(_orders, {
          threshold: 0.1,
          keys: [
            'order_number',
            'order_item_subtotal',
            'order_total_due',
            '_issueDate',
            '_order_status_string_translated',
          ],
        });

        return fuse.search(this.query).map((order) => order.item);
      }
      return _orders;
    },
    orderOptions() {
      return [
        {
          name: 'External Link',
          icon: 'external-link-alt',
          action: 'openExternalLink',
        },
        {
          name: 'Delete Order',
          icon: 'trash-alt',
          action: 'deleteOrder',
          hidden: ({ order }) => order.order_status === 3 || (order.fullpaymentreceived === 1),
        },
        {
          name: 'Download as PDF',
          icon: 'print',
          action: 'downloadPDF',
        },
      ];
    },
  },
  mounted() {
    this.$store.dispatch('core/getBillingInfo');

    const { order_number } = this.$route.params;

    setTimeout(() => {
      if (order_number) {
        this.orderSummary(order_number);
      }
    }, 1000);
  },
  methods: {
    triggerAction(name, data) {
      this[name](data);
    },
    deleteOrder(order) {
      this.$store.dispatch('core/deleteSaleOrder', order.order_number);
    },
    openExternalLink(order) {
      window.open(`/#/quote/${order.access_code}`, '_blank');
    },
    downloadPDF(order) {
      const orderNumber = order.order_number;
      window.open(
        `/core/?op=printsaleorder&ordernumber=${orderNumber}`,
        '_blank'
      );
    },
    orderSummary(orderNumber) {
      this.$modal.show('checkout', {
        step: 'summary',
        props: { orderNumber },
      });
    },
    getOrderStatus(order) {
      const today = dayjs();
      
      const expirationDate = dayjs(order.order_expiration_date);

      if (order.order_delivered == 1) {
        return 'delivered';
      } else if (order.fullpaymentreceived == 1) {
        return 'paid';
      } else if (!expirationDate.isToday() && today.diff(expirationDate) >= 0) {
        return 'expired';
      } else if (order.order_status == 3) {
        return 'invoice';
      }
      return 'quote';
    },
    formatInlinePrice
  },
};
</script>

<style></style>
